.header {
    height: 3.5rem;
    width: 100%;
    background-color: #fff;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    box-shadow: 0px 0px 15px 5px rgba(184, 247, 255, 0.75);
    cursor: pointer;
    position: fixed;
    top: 0;
    display: grid;
    gap: 15px;
    grid-template-columns: 2rem 2fr 2fr 1fr 10rem;
    padding-bottom: 10px;
}

.header .logo{
    grid-column: 2/3;
}

.header .logo a {
    display: grid;
    text-decoration: none;
    padding: 0;
}

.header .logo a h1 {
    color: purple;
    font-size: 1.65rem;
    padding-left: 10px;
    cursor: pointer;
}
.navbar{
    grid-column: 3/5;
}
.navbar ul li {
    display: inline-block;
    text-decoration: none;
    grid-column: 3/4;
}

.navbar ul li a,
button {
    text-decoration: none;
    cursor: pointer;
    font-family: 'Montserrat';
}

.navbar ul li a {
    padding: 0;
    grid-column: 3/4;
}

.navbar ul li a .text {
    padding-right: 15px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 1.6rem;
    color: black;
    transition: all 0.3s 0s;
    font-family: 'Montserrat';
}

.navbar ul li a .text:hover,
.navbarul li a.active .text {
    color: orange;
    transform: scale(1.5) translateY(-3px);
    padding-left: 25px;
    padding-right: 25px;
    letter-spacing: 1px;
}

.navbar ul li:nth-child(2) a .text:hover,
.navbar ul li:nth-child(2) a.active .text {
    color: rgba(0, 70, 255, 1);
}

.navbar ul li:nth-child(1) a .text:hover,
.navbar ul li:nth-child(1) a.active .text {
    color: rgb(53, 230, 53);
}

.header a {
    padding: 10px;
    padding-right: 15px;
}

.header a button {
    padding: 10px;
    font-size: 1rem;
    border-radius: 50px;
    background: rgba(0, 187, 255, 1);
    color: white;
    border: none;
    transition: all 0.3s 0s;
    font-weight: bold;
    font-family: 'Montserrat';
}

.header a button:hover {
    background: rgba(0, 187, 255, 0.8);
    transform: scale(1.2);
}

@media (max-width:950px) {
    .header{
        height: auto;
        grid-template-columns: 1px 4fr 2rem 1fr 1px;
    }
    .header .logo{
        grid-template-columns: 1/3;
    }
    .header .navbar {
        display: none;
    }
    .header a{
        display: none;
    }
}