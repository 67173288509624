* {
  scroll-behavior: smooth;
  font-family: 'Poppins';
}

::-webkit-scrollbar {
  width: 8px;
  scroll-behavior: smooth;
}


::-webkit-scrollbar-thumb {
  background: linear-gradient(lightblue, lightgreen);
  border-radius: 20px;
  scroll-behavior: smooth;
}