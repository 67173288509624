* {
    box-sizing: border-box;
    transition: 0.2s linear;
}

.dietcontainer {
    height: auto;
    width: 100%;
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
    font-family: 'Poppins';
}

.wrapper {
    margin: 10px auto;
    padding: 0 10%;
    padding-bottom: 10px;
    font-family: 'Poppins';
}

.wrapper .container {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
}

.wrapper .container .box {
    padding: 20px;
    justify-content: space-between;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.wrapper .container .box img {
    height: 160px;
    width: 200px;
}

.wrapper .container .box h3 {
    color: #444;
    padding: 10px 0;
    font-size: 20px;
    text-transform: capitalize;
}

.wrapper .container .box p {
    color: #777;
    font-size: 14px;
    line-height: 1.6;
    height: 70px;
}

.wrapper .container .box button {
    color: #fff;
    border: none;
    outline: none;
    font-size: 17px;
    margin-top: 10px;
    padding: 8px 15px;
    background: #333;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
    text-transform: capitalize;
}

.wrapper .container .box button:hover {
    letter-spacing: 1px;
}

.wrapper .container .box:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 15px rgba(184, 247, 255, 1);
}

.wrapper .container .box:hover:nth-child(1) {
    transform: scale(1.03);
    box-shadow: 0 10px 15px rgba(184, 255, 200, 1);
}

.wrapper .container .box:hover:nth-child(3) {
    transform: scale(1.03);
    box-shadow: 0 10px 15px rgba(255, 247, 184, 1);
}

.wrapper .container .box:hover:nth-child(4) {
    transform: scale(1.03);
    box-shadow: 0 10px 15px rgba(255, 184, 247, 1);
}

@media (max-width:770px) {
    .wrapper {
        padding: 20px;
    }
}