.homecontainer {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    background-color: none;
    max-height: 80vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
}
.homecontainer h1 {
    color: rgba(255, 10, 40, 0.8);
    font-size: 60px;
    margin: 5px 0;
    font-family: 'Poppins';
    padding-top: 3.5rem;
}

.homecontainer h2 {
    font-weight: lighter;
    font-size: 40px;
    margin: 2px 0;
    font-family: 'Poppins';
    color: #444;
}
.homecontainer img{
    max-width: 80vw;
    max-height: 80vh;
    min-height: 300px;
    min-width: 300px;
    padding-bottom: 3.5rem;
}

@media (max-width: 800px) {
    .homecontainer{
        padding-bottom: 0;
        margin-bottom: 0;
        height: 400px;
    }
}